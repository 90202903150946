.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  max-width: 1200px;
  width: 100%;
}

.grid-item {
  background-color: #ffffff;
  border: none;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  transition: box-shadow 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.grid-item:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.12);
  transform: translateY(-2px);
}

.grid-item h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #1da1f2;
  margin-bottom: 10px;
}

.grid-item p {
  margin: 0;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #657786;
  line-height: 1.5;
  text-align: center;
}

.link {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  transition: color 0.2s ease-out;
}

.link:hover {
  color: rgb(255, 221, 131)
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.headerRole {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background-color: #2d3142;
}

.titleRole {
  color: #f8f8f8;
  font-size: 3rem;
  font-weight: bold;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 30px;
  width: 80%;
  max-width: 800px;
  background-color: #f8f8f8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.description {
  color: #333333;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.salary {
  color: #2d3142;
  font-size: 2rem;
  font-weight: bold;
}

.stack {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  color: #333333;
  font-size: 1.5rem;
  
}

.stack {
  display: flex;
  flex-wrap: wrap; /* this allows the items to wrap to the next line if there isn't enough space */
}

.stack span {
  background-color: #249493;
  border-radius: 5px;
  padding: 2px 12px;
  margin: 4px;
  font-size: 0.9rem;
  color: white;
  font-weight: 300;
  display: flex;
  width: fit-content;

}

@media (max-width: 600px) { /* this applies the rules only if the viewport width is 600px or less */
  .stack span {
     /* this makes each item take up the full width of its container */
  }
}

.backHome {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 225, 225);
  border-radius: 20px;
  padding: 12px 14px;
  color: black;
  font-weight: 600;
}

.content-wrapper-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.button:hover {
  background-color: #f8f8f8;
  color: #2d3142;
}

.roleMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.roleFact {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Secular One", sans-serif;
  color: white;
  color: black;
  background-color: rgb(225, 225, 225);
  margin-bottom: 16px;
  padding: 16px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.btnDiv {
  margin-top: 20px;
}
.roleFact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}