@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  direction: rtl;
}



aside ul {
  padding-top: 64px;
}
.category {
  margin-bottom: 16px;
}

section {
  display: block;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  
}

header h2  {
  font-family: "Secular One", sans-serif;

}

header h1 {
  font-family: "Secular One", sans-serif;
}

nav button {
  padding: 3px 3px;
}

.align-start {
  align-self: start;
}


.main {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  gap: 48px;
  padding: 38px 64px 0px ;
  margin-top: 40px;
  padding-top: 100px;
}


.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 64px;
}

.main {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  gap: 48px;
  padding: 38px 64px 0px;
  margin-top: 40px;
  padding-top: 100px;
}

.category-filter {
  position: relative;
  
}



.btn-category {
  width: 100%;
  background-image: none;
  color: #ffffff;
}

.btn {
  border: none;
  border-radius: 50px;
  font-family: "Secular One", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-size: 17px;
  padding: 16px 0 13px;
  background-color: #a7c957;
  background-color: #ffffff;
  color: #000000;
  transition: 0.3;
  cursor: pointer;
} 

.btn-all {
  /* margin-bottom: 28px; */
  width: 100%;
  background-color: #ababab;
}

.fact {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Secular One", sans-serif;
  color: white;
  color: black;
  background-color: rgb(225, 225, 225);
  margin-bottom: 16px;
  padding: 16px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 7px;
  position: relative;
} 

/* .fact::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: ${({cat})};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
} */


.fact::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  width: 8px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: var(--tag-color);
}


.role {
  font-size: 22px;
  font-weight: 300;
  color: #00897B;
  padding-bottom: 7px;
  
}



.tag {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Secular One", sans-serif;
  font-weight: 500;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: fit-content;
  color: white;
}

.searchText {
  font-family: "Secular One", sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: #00897B;
}

.search-input {
  padding: 10px;
  border: none;
  background-color: #afafaf;
  color: #000000;
  font-size: 20px;
  font-family: "Secular One", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border-radius: 60px;
  margin: 10px;
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  translate: 50%;
  left: 58%;
  right: 58%;
  color: #000000;
  font-size: 20px;
  font-family: "Secular One", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 60px;
  margin-top: 10px;
}

.card {
  position: relative;
}
.card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 20px; /* Adjust this value to change the size of the rounded triangle */
  height: 20px; /* Adjust this value to change the size of the rounded triangle */
  background-color: #38b2ac; /* #38b2ac is the teal-600 color */
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 80% 80%);
  border-radius: 4px 6px 0 0; /* Adjust this value to change the roundness of the corner */
  z-index: 1;
}




 .pages-btns {
  margin: auto;
  padding: 8px;
  width: 63%;
}

.btn-page {
  padding: 8px;
  border: none;
  background-color: #6b6b6b;
  color: #ffffff;
  font-size: 20px;
  font-family: "Secular One", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 60px;
  margin: 8px;
}
.btn-page:hover {
  background-color: #928d8c;
  color: white;
}

.divfooter {
  text-align: center;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: #249493;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Rubik, Arial;
  font-size: .95rem;
  font-weight: 500;
  line-height: 14px;
  display: flex;
}

.divsocialbtn {
  width: 1460px;
  height: 62px;
  object-fit: cover;
  align-self: center;
  margin-bottom: 4px;
}

/* .tnwuijualdzvc {
  align-self: center;
  margin-bottom: 41px;
  margin-left: 1px;
}  */
.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 5px;
  padding-top: 25px;
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
}

.tnwuijualdzvc {
  align-self: center;
  margin-left: 1px;
  font-size: 0.98rem;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 30px;
}


.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.scroll-to-top-button.visible {
  opacity: 1;
}  

.message {

  font-weight: 600;
  
}

.form-group {
  margin-bottom: 6px;
}

label {
  display: block;
  font-size: 18px;
  margin-bottom: 6px;
  color: #000000;
  font-weight: 600;
} 

.form-text {
  width: 100%;
  padding: 12px;
  border: 2px solid #000000;
  border-radius: 10px;
  background-color: transparent;
  font-size: 16px;
  color: #292524;
  transition: box-shadow 0.2s ease-in-out;
}

/* input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
} */

/* button[type="submit"] {
  display: block;
  margin-top: 20px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin: 0 auto;

}

button[type="submit"]:hover {
  background-color: #292524;
} */

.fact-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  padding: 7px 30px;
  background: transparent;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

textarea {
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  font-family: inherit;
  width: 100%;
}

.fact-form input,
.fact-form select {
  width: 100%;
  
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  color: inherit;
  font-family: inherit;
}

.fact-form input::placeholder {
  color: #000000;
}
.fact-form textarea::placeholder {
  color: #000000;
}
.fact-form input:first-child {
  flex-grow: 1;
}

option {
  font-family: "Secular One", sans-serif;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 9px;
  border-radius: 10px;
  background-color: white;
}


.open-modal-btn {
  padding: 10px;
  border: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px;
  font-family: "Secular One", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 60px;
  margin: 10px;
}

.close-btn {
  padding: 10px;
  border: none;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px;
  font-family: "Secular One", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 60px;
  margin: 10px;
} 

 .close-btn:hover {
  background-color: #292524;
  color: white;
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}



@media (max-width: 768px) {

  .headerMobile {
    padding-top: 22px;
    
  }

  .searchDiv {
    display: none;
  }
  .socialHeader a {
    display: none;
  }

  .refreshHeader {
    font-size: 16px;
  }
  .header-content {
    padding: 0 16px;
    font-size: 9px;

  }
  /* .header-content h2 {
    display: none;
  } */
  nav ul {
    display: none;
  }
  .menu-btn {
    display: block;
  }
  .header-content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}




@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    height: auto;
  }
}

@media (max-width: 600px) {
  .fact-form {
    padding: 4px 10px;
  
  }
  .fact-form input {
    padding: 6px;
    font-size: 16px;
    
  } 
}


@media (max-width: 767px) {

  aside ul {
    padding-top: 24px;
  }

  .submitModal {
    width: 90%;
    
  }



  .modal-content {
    width: 90%;
  }

  .modal-content .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    
  }

  .fact {
    width: 100%;
  }

  .fact p {
    font-size: 14px;
  }

  .fact button {
    padding: 4px 10px;
  }

  .search-bar p {
    display: none;
  }


  .search-container {
    display: none;
  }
  .search-input {
    display: none;
  }
  .subhead {
    font-size: 8px;
  }

  .message {
    font-size: 8px;
  }
  h1 {
    font-size: 24px;
  }
  .email {
    margin-right: -50px;
    font-size: 12px;
  }
} 

@media (max-width: 600px) {

  .fact-form {
    padding: 16px;
  }

  .search-container {
    display: none;
  }
  .search-input {
    display: none;
  }

  h1 a {
    font-size: 22px;
  }

  h2 {
    font-size: 12px;
  }

  .header-social {
    display: block;
  }
}
@media (max-width: 1000px) {
  body {
    direction: rtl;
  }

  .search-container {
    display: none;
  }
  .search-input {
    display: none;
  }

  
}


@media (max-width: 768px) {
  .headerMobile {
    flex-direction: column;
  }
  .header-content {
    flex-direction: column;
  }
  /* Add other styles for mobile screens as needed */
}


.title-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .title-container {
    justify-content: flex-start;
  }
}


























